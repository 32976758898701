import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["departmentSelect", "municipalitySelect"]

  connect(){
    // this.handleChange()
  }

  handleChange() {
    let departmentSelectElement = this.departmentSelectTarget
    let municipalitySelectElement = this.municipalitySelectTarget
    let optionSelected = departmentSelectElement.options[departmentSelectElement.selectedIndex]
    let municipalityUrl = optionSelected.dataset.url

    municipalitySelectElement.innerText = null
    if (!optionSelected.value == '' || !optionSelected.value == undefined) {
      this.loadMunicipalities(municipalityUrl)
    }
  }

  loadMunicipalities(url) {
    let _this = this
    fetch(url)
    .then(response => response.json())
    .then(data => _this.dataForMunicipality(data))
  }

  dataForMunicipality(data) {
    this.addDefaultOption()
    data.forEach( municipality => this.generateOptionForMunicipalitySelect(municipality) )
  }

  addDefaultOption() {
    let opt = document.createElement('option')
    opt.textContent = 'Municipios'
    opt.value = 0
    this.municipalitySelectTarget.appendChild(opt)
  }

  generateOptionForMunicipalitySelect(municipalityData) {
    let opt = document.createElement('option')
    opt.textContent = municipalityData[0]
    opt.value = municipalityData[1]
    this.municipalitySelectTarget.appendChild(opt)
  }

}