
export default class KindButton {
  constructor(contentDiv, kindObj, point){
    this.contentDiv = contentDiv
    this.label = kindObj.name
    this.kindId = kindObj.id
    this.point = point
    this.divControlUI = null
    this.create()
  }

  create(){
    const controlUI = document.createElement("div")
    this.divControlUI = controlUI

    controlUI.style.backgroundColor = "#fff"
    controlUI.style.border = "2px solid #fff"
    controlUI.style.borderRadius = "3px"
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)"
    controlUI.style.cursor = "pointer"
    controlUI.style.marginTop = "8px"
    controlUI.style.marginBottom = "22px"
    controlUI.style.marginLeft = "10px"
    controlUI.style.textAlign = "center"
    controlUI.title = this.label
    this.contentDiv.appendChild(controlUI)

    // Set CSS for the control interior.
    const controlText = document.createElement("div")

    controlText.style.color = "rgb(25,25,25)"
    controlText.style.fontFamily = "Roboto,Arial,sans-serif"
    controlText.style.fontSize = "16px"
    controlText.style.lineHeight = "38px"
    controlText.style.paddingLeft = "5px"
    controlText.style.paddingRight = "5px"
    controlText.innerHTML = this.label
    controlUI.appendChild(controlText)

    // Setup the click event listeners
    controlUI.addEventListener("click", () => {
      this.point.filterByKind(this.kindId, this)
    })
  }

  putActive() {
    this.divControlUI.style.backgroundColor = "#bed73d"
    this.divControlUI.style.border = "2px solid #bed73d"
  }
  
  putInactive() {
    this.divControlUI.style.backgroundColor = "#fff"
    this.divControlUI.style.border = "2px solid #fff"
  }
}