import { Controller } from "stimulus"
import KindButton from "../ui_controls/kind_button"
import markerIcon from "../images/marker-green-caja-zacate.png"

export default class extends Controller {
  static targets = ["map", "info", "departmentSelect", "municipalitySelect"]
  markers = []
  mainServicePointList = []
  filteredServicePointList = []
  defaultLtdLng = {lat: 13.794185, lng: -88.89653}

  kinds = [
    { id: 1, name: "ATM-Cajeros Automáticos" },
    // { id: 2, name: "ATM Depositario" },
    { id: 3, name: "CNB" },
    { id: 4, name: "Oficinas"}
  ]
  kindIdsSelectedList = []
  kindButtonsList = []

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }

  initializeMap() {
    this.map()
    this.loadPlaces('')
  }

  map() {
    if (this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(
          13.794185,
          -88.89653
        ),
        zoom: 9,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      })

      this.createUIButtonsKindsFilters()
    }
    return this._map
  }

  loadPlaces(searchParams) {
    let _this = this
    fetch('/service_points/search?' + searchParams)
    .then(response => response.json())
    .then(data => _this.putMarkers(data))
  }

  loadMunicipalities(url) {
    let _this = this
    fetch(url)
    .then(response => response.json())
    .then(data => _this.dataForMunicipality(data))
  }

  putMarkers(data) {
    data.forEach(servicePoint => this.generateMarkerAndClickEvent(servicePoint))
  }

  generateMarkerAndClickEvent(servicePoint) {
    let serviceMarker = servicePoint.marker_attributes
    let mapMarker = this.createMarker(serviceMarker.latitude, serviceMarker.longitude)
    servicePoint.mapMarker = mapMarker
    this.mainServicePointList.push(servicePoint)

    mapMarker.addListener("click", () => {
      this.map().setCenter(mapMarker.getPosition())
      this.showInfo(servicePoint)
    })
  }

  dataForMunicipality(data) {
    this.addDefaultOption()
    data.forEach( municipality => this.generateOptionForMunicipalitySelect(municipality) )
  }

  createMarker(latitude, longitude) {
    this._marker = new google.maps.Marker({
      map: this.map(),
      anchorPoint: new google.maps.Point(0, 0),
      icon: markerIcon
    })
    let mapLocation = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude)
    }
    this._marker.setPosition(mapLocation)
    this._marker.setVisible(true)

    this.markers.push(this._marker)

    return this._marker
  }

  generateOptionForMunicipalitySelect(municipalityData) {
    let opt = document.createElement('option')
    opt.textContent = municipalityData[0]
    opt.value = municipalityData[1]
    opt.setAttribute('data-marker', municipalityData[2].marker)
    this.municipalitySelectTarget.appendChild(opt)
  }

  addDefaultOption() {
    let opt = document.createElement('option')
    opt.textContent = 'Municipios'
    opt.value = ''
    this.municipalitySelectTarget.appendChild(opt)
  }

  showInfo(servicePoint) {
    let contentHtml = ''
    contentHtml= "<div class='card card-body info'>"
    contentHtml += "<h4 class='title-service'>"+ servicePoint.name +"</h4>"
    if (servicePoint.address != '') {
      contentHtml += "<p><strong>Dirección:</strong> <br/>"+ servicePoint.address +"</p>"
    }
    if (servicePoint.phone_number != '') {
      contentHtml += "<p><strong>Teléfono:</strong> <br/>"+ servicePoint.phone_number +"</p>"
    }
    if (servicePoint.email != '') {
      contentHtml += "<p><strong>Email:</strong> <br/>"+ servicePoint.email +"</p>"
    }
    if (servicePoint.content != '') {
      contentHtml += "<p><strong>Información:</strong> <br/>"+ servicePoint.content +"</p>"
    }
    contentHtml += "</div>"
    this.infoTarget.innerHTML = contentHtml
  }

  clearFilters() {
    this.infoTarget.innerHTML = ""
    this.mainServicePointList.forEach(servicePoint => {
      servicePoint.mapMarker.setMap(this.map())
    })

    this.departmentSelectTarget.value = ''
    this.municipalitySelectTarget.value = ''
    this.filteredServicePointList = []

    this.map().setCenter(this.defaultLtdLng)
    this.map().setZoom(9)
  }

  departmentChange() {
    let departmentSelectElement = this.departmentSelectTarget
    let municipalitySelectElement = this.municipalitySelectTarget
    let optionSelected = departmentSelectElement.options[departmentSelectElement.selectedIndex]
    let markerObj = JSON.parse(optionSelected.dataset.marker)
    let municipalityUrl = optionSelected.dataset.url

    municipalitySelectElement.innerText = null

    this.showMarkerByDepartment(departmentSelectElement.value)

    this.loadMunicipalities(municipalityUrl)
  }

  municipalityChange() {
    let municipalitySelectElement = this.municipalitySelectTarget
    this.showMarkerByMunicipality(municipalitySelectElement.value)
  }

  hideMakers(){
    this.mainServicePointList.forEach(servicePoint => {
      servicePoint.mapMarker.setMap(null)
    })
  }

  showMarkerByDepartment(departmentId) {
    let bounds = new google.maps.LatLngBounds()
    this.hideMakers()
    this.putInactiveKindButtonsList()
    this.filteredServicePointList = []

    this.mainServicePointList.forEach(servicePoint => {
      if (servicePoint.department_id == parseInt(departmentId)) {
        servicePoint.mapMarker.setMap(this.map())
        this.filteredServicePointList.push(servicePoint)
        bounds.extend(servicePoint.mapMarker.getPosition())
      }
    })
    this.map().fitBounds(bounds, {top: 50})
  }

  showMarkerByMunicipality(municipalityId) {
    let bounds = new google.maps.LatLngBounds()
    this.hideMakers()
    this.putInactiveKindButtonsList()
    this.filteredServicePointList = []

    this.mainServicePointList.forEach(servicePoint => {
      if (servicePoint.municipality_id == parseInt(municipalityId)) {
        servicePoint.mapMarker.setMap(this.map())
        this.filteredServicePointList.push(servicePoint)
        bounds.extend(servicePoint.mapMarker.getPosition())
      }
    })
    this.map().fitBounds(bounds, {top: 50})
  }

  createUIButtonsKindsFilters() {
    this.kinds.forEach(kind => {
      const leftControlDiv = document.createElement('div')
      const btnKind = new KindButton(leftControlDiv, kind, this)
      this.kindButtonsList.push(btnKind)
      this.map().controls[google.maps.ControlPosition.TOP_CENTER].push(leftControlDiv)
    })
  }

  // Esta funcion se manda a llamar en el click de los botones
  // que se crean la clase KindButton
  filterByKind(kindId, kindButton) {
    if (this.kindIdsSelectedList.includes(kindId)) {
      this.kindIdsSelectedList = this.kindIdsSelectedList.filter(item => item != kindId )
      kindButton.putInactive()
    } else {
      kindButton.putActive()
      this.kindIdsSelectedList.push(kindId)
    }
    this.fillServicePointListByKinds()
  }

  fillServicePointListByKinds() {
    this.hideMakers()
    let servicePointFiltered = []
    let currentServicePoints = []

    if (this.filteredServicePointList.length > 0) {
      currentServicePoints = this.filteredServicePointList
    } else {
      currentServicePoints = this.mainServicePointList
    }

    this.kindIdsSelectedList.forEach(kindId => {
      currentServicePoints.forEach(servicePoint => {
        if (servicePoint.kinds.includes(kindId)) {
          servicePointFiltered[servicePoint.id] = servicePoint
        }
      })
    })

    if (servicePointFiltered.length > 0) {
      this.showServicePointList(servicePointFiltered)
    }

    if (this.kindIdsSelectedList.length == 0){
      this.showFilteredServicePointList()
    }
  }

  showServicePointList(servicePointList) {
    if (servicePointList.length > 0) {
      let bounds = new google.maps.LatLngBounds()
  
      servicePointList.forEach(servicePoint => {
        servicePoint.mapMarker.setMap(this.map())
        bounds.extend(servicePoint.mapMarker.getPosition())
      })
      this.map().fitBounds(bounds, {top: 50})
    } else {
      this.showFilteredServicePointList()
    }
  }

  showFilteredServicePointList() {
    if (this.filteredServicePointList.length > 0) {
      this.showServicePointList(this.filteredServicePointList)
    } else {
      this.clearFilters()
    }
  }

  putInactiveKindButtonsList() {
    this.kindButtonsList.forEach(kindButton => {
      kindButton.putInactive()
    })
    this.kindIdsSelectedList = []
  }

}