// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import * as bootstrap from 'bootstrap'
import '../stylesheets/application'

import '../../../vendor/assets/javascripts/argon-dashboard.min.js'
import '../../../vendor/assets/stylesheets/argon-dashboard.min.css'

import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/css/all.css'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"

window.dispatchPointMapsEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("point-google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

window.dispatchAdminMapsEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("point-google-admin-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}