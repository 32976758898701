import { Controller } from "stimulus"
import SlimSelect from "slim-select"
import "slim-select/dist/slimselect.css"

export default class extends Controller {
  static values = { list: Array }

  connect(){
    let _this = this
    let list = _this.listValue

    new SlimSelect({
      select: this.element,
      data: list
    })
  }
}
